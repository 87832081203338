<template>
  <div class="page-box pp-search-line">
    <div class="pp-search-left">
    </div>
    <div class="pp-search-right">
      <span class="pp-search-input">
        <input type="text" v-model="term" @keyup.enter="handleSearch">
        <span class="search-clear" v-show="showClear" @click.stop="clearTerm">
          <span class="clear-box-inner">
            <a class="clear-btn"></a>
          </span>
        </span>
      </span>
      <div class="button" @click.stop="handleSearch"><div class="pp-search-icon"></div></div>
      <div class="pp-me">
        <router-link target="_blank" :to="{name: 'helpme'}" @click.native="loginValid">我的求助</router-link>
      </div>
      <a class="search-ad-btn" @click.stop="handleHelp">添加求助</a>
      <!-- <router-link target="_blank" class="search-ad-btn-2" :to="{name: 'helpintro'}">求助说明</router-link> -->
      <!-- <router-link target="_blank" class="search-ad-btn-3" :to="{name: 'helpprize'}">奖品中心</router-link> -->
      <router-link target="_blank" class="search-ad-btn-2" :to="{name: 'helper'}">应助排行榜</router-link>
      <router-link target="_blank" class="search-ad-btn-3" :to="{name: 'myhelp'}">我的应助</router-link>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      term: '',
      showClear: false
    }
  },
  watch: {
    term (val) {
      if (val) {
        this.showClear = true
        this.$emit('on-term', val)
      } else {
        this.showClear = false
        this.$emit('on-term', '')
      }
    }
  },
  methods: {
    clearTerm () {
      this.term = ''
    },
    handleSearch () {
      this.$emit('on-search', this.term)
    },
    handleHelp (e) {
      if (!this.$parent.loggedIn) {
        e.preventDefault()
        // updateStorageItem('route', {name: 'helpme'})
        this.$parent.showLoginTipWin = true
        return false
      } else {
        this.$emit('on-help')
      }
    },
    loginValid (e) {
      if (!this.$parent.loggedIn) {
        e.preventDefault()
        // updateStorageItem('route', {name: 'helpme'})
        this.$parent.showLoginTipWin = true
        return false
      } else {
        return true
      }
    }
  }
}
</script>
<style lang="less">
.pp-search-line {
  padding: 1.5rem 0;
  display: flex;
  justify-content: center;
}
.pp-search-left {
  height: 2.5rem;
  line-height: 2.5rem;
  margin-right: 1rem;
  color: #494440;
  font-size: 16px;
}
.pp-search-input {
  display: inline-block;
  vertical-align: top;
  position: relative;
  input {
    display: inline-block;
    max-width: none;
    border: 0.5px solid #aeb0b5;
    border-right: 0;
    padding-right: 3rem;
    padding-left: 0.75rem;
    height: 2.5rem;
    width: 30rem;
    font-size: 1rem;
    outline: 0;
    transition: box-shadow 0.15s;
    &:focus {
      border-color: #205493;
    }
  }
  .search-clear {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    height: 2.5rem;
    box-shadow: -0.4rem 0 0.6rem -0.3rem rgba(0,0,0,0.1);
  }
  .clear-box-inner {
    display: flex;
    padding: 0.5rem;
  }
}
.pp-search-right {
  position: relative;
  padding-right: 1rem;
  display: flex;
  justify-content: flex-end;
  &> .button {
    position: relative;
    background-color: #0071bc;
    transition: background-color 0.15s;
    font-size: 1.125rem;
    border: none;
    color: #fff;
    height: 2.55rem;
    line-height: 2.55rem;
    width: 8.6rem;
    cursor: pointer;
  }
  &> .pp-me {
    height: 2.55rem;
    line-height: 2.55rem;
    margin-left: 5px;
    font-size: 14px;
    display: flex;
    align-items: center;
    input {
      outline: 0;
      zoom: 1.2;
    }
    input, label {
      cursor: pointer;
      color: #0755b9;
    }
  }
  &> .search-ad-btn {
    position: absolute;
    left: 0;
    top: 2.7rem;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      color: #551A8B;
    }
  }
  &> .search-ad-btn-2 {
    position: absolute;
    left: 4rem;
    top: 2.7rem;
    font-size: 12px;
  }
  &> .search-ad-btn-3 {
    position: absolute;
    left: 8rem;
    top: 2.7rem;
    font-size: 12px;
  }
  &> .search-ad-btn-4 {
    position: absolute;
    left: 12rem;
    top: 2.7rem;
    font-size: 12px;
  }
  &> .search-ad-btn-5 {
    position: absolute;
    left: 16.5rem;
    top: 2.7rem;
    font-size: 12px;
  }
}
.pp-search-icon {
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  border: 1px solid #fff;
  border-radius: 50%;
  position: relative;
  margin: 0.5rem auto 0 auto;
  &:after {
    content: ' ';
    position: absolute;
    background: #fff;
    width: 0.8rem;
    height: 0.1rem;
    top: 1.3rem;
    left: 0.9rem;
    transform: rotate(45deg);
  }
}
@media screen and (max-width: 450px) {
  .pp-search-input > input {
    width: 100px;
  }
}
@media screen and (max-width: 600px) and (min-width: 450px) {
  .pp-search-input > input {
    width: 150px;
  }
}
@media screen and (max-width: 750px) and (min-width: 600px) {
  .pp-search-input > input {
    width: 200px;
  }
}
@media screen and (max-width: 850px) and (min-width: 750px) {
  .pp-search-input > input {
    width: 250px;
  }
}
@media screen and (max-width: 950px) and (min-width: 850px) {
  .pp-search-input > input {
    width: 350px;
  }
}
@media screen and (max-width: 1050px) and (min-width: 950px) {
  .pp-search-input > input {
    width: 400px;
  }
}
</style>
