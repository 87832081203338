<template>
  <div>
    <loading-bar ref="loadingbar"></loading-bar>
    <div class="pf-search-box">
      <pp-search-form @on-term="onTerm" @on-search="onSearch" @on-filter="onFilter" @on-help="onHelp"></pp-search-form>
    </div>
    <div class="pf-list page-box">
      <div class="pf-item head">
        <div class="idx">
          <div class="num-index">序号</div>
        </div>
        <div class="item">
          <div class="h-item">
            <div class="headimg"></div>
            <div class="title">标题</div>
            <div class="pmid">PMID</div>
            <div class="status">状态</div>
            <div class="time">求助时间</div>
            <div class="action">操作</div>
          </div>
        </div>
      </div>
      <div class="pf-item" v-for="(item, index) in fulltextList" :key="item.id">
        <div class="idx">
          <div class="num-index">{{ pageIndex*pageSize+index+1 }}</div>
        </div>
        <div class="item">
          <help-item :hitem="item"
            :unionid="authUnionid"
            @on-item-helping="onItemHelping"
            @on-item-delete="onItemDelete"
             @on-file-renew="onItemRenew"
            @on-file-upload="onFileUpload"
            @on-help-confirm="onHelpConfirm"></help-item>
        </div>
      </div>
      <div class="help-pagination" v-if="sum>0">
        <pagination pageSizeChangeable :total="sum" :pageIndex="pageIndex" :pageSize="pageSize" placement="top"
          @on-currentpage-change="onPageChange" @on-pagesize-change="onPageSizeChange"></pagination>
      </div>
    </div>
    <help-add-win :show="showAddWin" @on-hide="showAddWin=false" @on-ok="onHelpAdd"></help-add-win>
    <login-tip-win2 :show="showLoginTipWin" @on-hide="showLoginTipWin=false" text="微信扫码登陆后可操作"></login-tip-win2>
    <tooltip-win :show="showTipWin" @on-hide="showTipWin=false" :tipType="tipType" :duration="duration" :text="tipText"></tooltip-win>
    <loading-circle2 v-show="showLoadingCircle"></loading-circle2>
  </div>
</template>
<script>
import PpSearchForm from './help-search-form'
import HelpItem from './help-item'
import HelpAddWin from './help-add-win'
import LoginTipWin2 from '../login-tip-win/login-tip-win2'
import TooltipWin from '../login-tip-win/tooltip-win'
import Pagination from '../common/pagination/pagination'
import LoadingBar from '../common/loading/loading-bar'
import LoadingCircle2 from '../common/loading/loading-circle2'
import Login from '../../utils/login'
import { getStorageItem } from '../../utils/cache'
import { authenticate, catchExpired } from '../../utils/auth'
export default {
  components: {PpSearchForm, HelpItem, HelpAddWin, LoginTipWin2, TooltipWin, Pagination, LoadingBar, LoadingCircle2},
  mixins: [Login],
  data () {
    return {
      term: '',
      pageIndex: 0,
      pageSize: 50,
      sum: 0,
      fulltextList: [],
      showLoginTipWin: false,
      showAddWin: false,
      authUnionid: '',

      showTipWin: false,
      tipType: '',
      duration: '',
      tipText: '',

      showLoadingCircle: false
    }
  },
  mounted () {
    document.title = '文献互助'
    this.getAuthUnionid()
    this.loadPFList()
  },
  computed: {
    loggedIn () {
      let auth = getStorageItem('auth')
      let loggedAuth = this.$store.state.auth.loggedAuth
      let isLogin = this.$store.getters.getLoginState
      return auth && loggedAuth && isLogin
    }
  },
  methods: {
    onTerm (term) {
      this.term = term
    },
    onSearch (term) {
      this.loadPFList()
    },
    loadPFList () {
      this.$refs.loadingbar.start()
      this.$http.get(`${this.httpRoot}/paper/fulltext/list`, authenticate({params: {
        query: this.term,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.fulltextList = res.fulltextList || []
          this.sum = res.sum || 0
          this.$refs.loadingbar.finish()
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.$refs.loadingbar.error()
      })
    },
    onPageChange (pageNum) {
      this.pageIndex = pageNum - 1
      this.$el.scrollIntoView()
      //
      this.loadPFList()
    },
    onPageSizeChange (size) {
      this.pageIndex = 0
      this.pageSize = size
      this.loadPFList()
    },
    getAuthUnionid () {
      this.$http.get(`${this.httpRoot}/auth/unionid`, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.authUnionid = res.unionid || 'me'
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    onItemHelping () {
      this.loadPFList()
    },
    onItemDelete () {
      this.loadPFList()
    },
    onItemRenew () {
      this.loadPFList()
    },
    onHelpConfirm () {
      this.loadPFList()
    },
    onHelp () {
      this.showAddWin = true
    },
    onHelpAdd (helpObj) {
      this.$http.post(`${this.httpRoot}/paper/fulltext/add`, helpObj, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.loadPFList()
        } else {
          this.fulltextAddFaild(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    onFileUpload () {
      this.loadPFList()
    },
    fulltextAddFaild (text) {
      this.tipType = 'warning'
      this.duration = 1000
      this.tipText = text || '服务异常'
      this.showTipWin = true
    },
    fulltextAuthFailed (text) {
      this.tipType = 'warning'
      this.duration = 1000
      this.tipText = text || '不可以应助自己的请求'
      this.showTipWin = true
    },
    showCopyTitleSuccess () {
      this.tipType = 'success'
      this.duration = 1000
      this.tipText = '标题复制成功！'
      this.showTipWin = true
    },
    showCopyTitleLinkSuccess () {
      this.tipType = 'success'
      this.duration = 1000
      this.tipText = '标题和链接复制成功！'
      this.showTipWin = true
    },
    needFailedDesc () {
      this.tipType = 'warning'
      this.duration = 1000
      this.tipText = '填写失败原因'
      this.showTipWin = true
    }
  }
}
</script>
<style lang="less">
.pf-search-box {
  background-color: #f1f1f1;
  border-bottom: 1px solid #ccc;
}
.pf-list {
  position: relative;
}
.pf-item {
  position: relative;
  padding: 5px 0;
  .idx {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 8px;
    font-size: 13px;
  }
  .num-index {
    padding-left: 5px;
  }
  .item {
    margin-left: 2.5rem;
  }
  &.head {
    background-color: #e9eaef;
    border-bottom: 1px solid #ddd;
  }
}
.pf-list .pf-item:nth-child(2n) {
  background: #fafafa;
}
.help-pagination {
  margin: 15px 0;
}
</style>
