<template>
  <div class="pagesize" @click.stop="handleShow">
    <div class="pagesize-rel">
      <span>{{`${currentSize} 条/页`}}</span>
      <i class="fa fa-caret-down"></i>
    </div>
    <transition name="fade">
      <div class="pagesize-popper" :class="[placement==='top'?'top':'']" v-show="visible">
        <div class="pagesize-list">
          <div
            v-for="(size, index) in pageSizeList"
            :key="index"
            class="pagesize-list__item"
            :class="{active: currentSize === size}"
            @click.stop="pageSizeChange(size)">{{`${size} 条/页`}}</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'PageSize',
  props: {
    pageSize: Number,
    placement: String
  },
  data () {
    return {
      visible: false,
      pageSizeList: [20, 50, 100],  // [20, 50, 100, 200, 500],
      currentSize: this.pageSize || 20
    }
  },
  watch: {
    pageSize (val) {
      this.currentSize = val
    }
  },
  created () {
    document.addEventListener('click', this.handleBlur)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.handleBlur)
  },
  methods: {
    handleShow () {
      this.timeout = setTimeout(() => {
        this.visible = !this.visible
      }, 0)
    },
    handlePos () {
      //
    },
    handleBlur (e) {
      clearTimeout(this.timeout)
      if (!this.$el.contains(e.target)) {
        this.visible = false
      }
    },
    pageSizeChange (size) {
      if (this.currentSize !== size) {
        this.currentSize = size
        this.visible = false
        this.$emit('on-pagesize-change', size)
      }
    }
  }
}
</script>
<style lang="less">
.pagesize {
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  font-size: 13px;
  margin: 0 10px;
  width: 93px;
  border-radius: 2px;
  border: 1px solid #b6c3d0;
  float: left;
}
.pagesize-rel {
  display: inline-block;
  position: relative;
  span {
    width: 68px;
    text-align: right;
  }
}
.pagesize-popper {
  display: block;
  font-size: 12px;
  line-height: 1.5;
  position: relative;
  z-index: 1;
  &.top {
    top: -30px;
    transform: translateY(-100%);
  }
}
.pagesize-list {
  background: #fff;
  box-shadow: 0 0px 5px rgba(50, 50, 50, 0.3);
  width: 91px;
  padding: 5px 0;
  border-radius: 2px;
}
.pagesize-list__item {
  text-align: center;
  height: 28px;
  line-height: 28px;
  &:hover {
    background: #eee;
  }
  &.active {
    background: #0071bc;
    color: #fff;
  }
}
</style>
