<template>
  <div class="cu-input-wrapper">
    <div class="cu-input">
      <input v-model="currentValue" :placeholder="placeholder"
        @change="handleInputChange"
        @keyup.enter="handleEnter"/>
      <span v-if="clearable" class="clear-btn-wrapper" @click.stop="handleInputClear">
        <span class="clear-box">
          <a class="clear-btn"></a>
        </span>
      </span>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    value: [String, Number, Boolean],
    placeholder: String,
    clearable: Boolean
  },
  data () {
    return {
      currentValue: this.value || ''
    }
  },
  watch: {
    value (val) {
      this.currentValue = val || ''
    },
    currentValue (val) {
      this.$emit('input', val)
      this.$emit('on-change', val)
    }
  },
  methods: {
    handleInputChange (e) {
      this.$emit('on-input-change', e.target.value)
    },
    handleInputClear () {
      this.currentValue = ''
    },
    handleEnter () {
      this.$emit('on-enter', this.currentValue)
    }
  }
}
</script>
<style lang="less">
.cu-input-wrapper {
  position: relative;
}
.cu-input {
  position: relative;
  height: 32px;
  line-height: 32px;
  &> input {
    box-sizing: border-box;
    font-size: 0.8125rem;
    height: 32px;
    padding-left: 7px;
    padding-right: 32px;
    margin: 0;
    width: 100%;
    outline: 0;
    border: 1px solid #b6c3d0;
    border-radius: 3px;
    transition: border-color 0.2s;
    &:focus {
      border-color: #037dce;
    }
  }
  &:hover {
    &> .clear-btn-wrapper {
      display: block;
    }
  }
  &> .clear-btn-wrapper {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    font-size: 70%;
    display: none;
  }
}
</style>
