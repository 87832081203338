<template>
  <modal v-model="showModal" dimmer maskClose hideClose :hideFooter="false" title="求助" @on-ok="onOk" @on-cancel="onCancel">
    <div class="group-add-item">
      <span class="required">标题：</span>
      <y-input v-model="title"></y-input>
    </div>
    <div class="groups-items">
      <div class="groups-add-item">
        <span class="required">PMID：</span>
        <y-input v-model="pmid"></y-input>
      </div>
      <div class="groups-add-item">
        <span class="required">DOI：</span>
        <y-input v-model="doi"></y-input>
      </div>
    </div>
  </modal>
</template>
<script>
import Modal from '../common/modal/modal'
import YInput from '../common/input/input'
export default {
  components: { Modal, YInput },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    value: String
  },
  data () {
    return {
      showModal: this.show,
      title: '',
      pmid: '',
      doi: ''
    }
  },
  watch: {
    show (val) {
      this.showModal = val
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide')
      }
    }
  },
  methods: {
    onOk () {
      if (this.title && this.pmid && this.doi) {
        this.$emit('on-ok', {
          title: this.title,
          pmid: Number(this.pmid),
          doi: this.doi
        })
        this.showModal = false
      }
    },
    onCancel () {
      this.showModal = false
    }
  }
}
</script>
<style lang="less">
.group-add-item, .groups-items {
  padding: 15px;
  display: flex;
  align-items: center;
  input {
    width: 500px;
  }
  .required {
    position: relative;
    &::before {
      content: "*";
      color: red;
      transform: translate(-100%, 50%);
    }
  }
}
.groups-add-item {
  padding: 15px;
  display: flex;
  align-items: center;
  input {
    width: 200px;
  }
}
</style>
